import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 Debasish Behera - Made with 💻 & ☕</p>
    
    </footer>
  );
}

export default Footer;



{/* <div className="social-links">
<a href="https://github.com" target="_blank" rel="noopener noreferrer">GitHub</a>
<a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
<a href="mailto:debasish@example.com">Email</a>
</div> */}